import React, { useState, useEffect } from 'react';
import Pagination from './Pagination/Pagination';
import './Table.css';
import Badge from '../Badge/Badge';
import ActionButton from '../ActionButton/ActionButton';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import ls from 'local-storage';
import moment from 'moment';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { calculateTimeFormat } from '../../utils/timeFormatter';

function Table(props) {
  const history = useHistory();

  const columnHeader = props.header
    ? props.header
    : [
        { path: 'id', title: 'No.' },
        { path: 'firstName', title: 'First Name' },
        { path: 'lastName', title: 'Last Name' },
        { path: 'email', title: 'Email' },
      ];
  const tableData = props.data ? props.data : [''];

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [visible, setVisible] = useState(false);
  const [deleteTranscriptVisible, setDeleteTranscriptVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [id, setId] = useState('');
  //Get Current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const handleDeleteTranscriptionClose = () => {
    setDeleteTranscriptVisible(false);
  };
  const handleTranscriptDelete = (transcriptId) => {
    setDeleteTranscriptVisible(true);
    setId(transcriptId);
  };
  const handleDeleteTranscriptionConfirm = async () => {
    try {
      const reqHeaders = {
        authorization: ls.get('accessToken'),
      };

      var response = await axios.post(
        `${process.env.REACT_APP_API_URL}transcript/deleteTranscript`,
        {
          transcriptId: id,
        },
        {
          headers: reqHeaders,
        }
      );

      if (response.data.statusCode === 200) {
        // console.log('response data', response.data);
        setId('');
        props.reloadTranscriptions();
        // alert(response.data.message);
        alert('Deleted successfully!');
      }
    } catch (err) {
      console.log('err', err);
    }
  };
  const handleDeleteConfirm = async () => {
    try {
      // console.log('circular data?', email);
      const reqHeaders = {
        authorization: ls.get('accessToken'),
      };

      var response = await axios.post(
        `${process.env.REACT_APP_API_URL}users/deleteUser`,
        {
          email: email,
        },
        {
          headers: reqHeaders,
        }
      );

      if (response.data.statusCode === 200) {
        // console.log('response data', response.data);
        props.reloadFreelancers();
        alert('Successfully deleted freelancer');
      }
    } catch (err) {
      console.log('err', err);
    }
  };
  const openDeleteModal = async (email) => {
    setEmail(email);
    setVisible(true);
  };

  const handleAction = (eventType, id, data) => {
    if (eventType === 'view' || eventType === 'edit')
      history.push({
        pathname: `/Transcriptions/AudioTranscription_${id}`,
        state: {
          // location state
          transcription: data,
        },
      });
    // console.log(`${eventType} ${id} ${data}`);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    !loading && (
      <>
        <div className="table-wrapper">
          <div className="table-top"></div>
          <table className="tbl">
            <thead className="tbl-header">
              <tr>
                {columnHeader.map(({ path, title }) => (
                  <th
                    key={path}
                    className={
                      path === 'id'
                        ? 'sr-no'
                        : path === 'action'
                        ? 'action-title'
                        : ''
                    }
                  >
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="tbl-body">
              {Object.keys(currentRows).map(function (key, index) {
                return (
                  <tr key={key}>
                    {columnHeader.map(({ path }) => {
                      if (path === 'status') {
                        // console.log('current', currentRows[index][path]);
                        return (
                          <td key={path}>
                            <Badge type={currentRows[index][path]} />{' '}
                          </td>
                        );
                      } else if (path === 'duration') {
                        return (
                          <td key={path}>
                            {currentRows[index][path] &&
                              calculateTimeFormat(currentRows[index][path])}
                          </td>
                        );
                      } else if (
                        path === 'createdOn' ||
                        path === 'registeredOn'
                      ) {
                        return (
                          <td key={path}>
                            {currentRows[index][path] &&
                              moment(currentRows[index][path])
                                .utc()
                                .format('DD MMMM YYYY, hh:mm a')}
                            {/* {(currentRows[index][path])} */}
                          </td>
                        );
                      } else if (path === 'action') {
                        if (
                          currentRows[index][path] === 'delete' &&
                          props.isAdmin
                        ) {
                          // console.log('delete!', currentRows[index][path]);
                          var email = String(currentRows[index]['email']);
                          return (
                            <td key={path} className="single-data">
                              {/* <ActionButton type={'edit'} /> */}
                              <ActionButton
                                type={currentRows[index][path]}
                                onClick={() => openDeleteModal(email)}
                              />{' '}
                              {/* for Delete */}
                            </td>
                          );
                        } else if (
                          currentRows[index][path] === 'edit' &&
                          props.isAdmin
                        ) {
                          const transcriptId = currentRows[index]['_id'];
                          return (
                            <td key={path} className="multiple-data">
                              <ActionButton
                                type={currentRows[index][path]}
                                onClick={() =>
                                  handleAction(
                                    currentRows[index][path],
                                    currentRows[index]['_id'],
                                    currentRows[index]
                                  )
                                }
                              />{' '}
                              {/* for Delete */}
                              <ActionButton
                                type={'delete'}
                                onClick={() =>
                                  handleTranscriptDelete(transcriptId)
                                }
                              />
                            </td>
                          );
                        } else {
                          return (
                            <td key={path} className="single-data">
                              {currentRows[index]['status'] !==
                              'transcribing' ? (
                                <ActionButton
                                  type={currentRows[index][path]}
                                  onClick={() =>
                                    handleAction(
                                      currentRows[index][path],
                                      currentRows[index]['_id'],
                                      currentRows[index]
                                    )
                                  }
                                />
                              ) : (
                                <ActionButton />
                              )}
                            </td>
                          );
                        }
                      } else {
                        return (
                          <td
                            className={path === 'id' ? 'sr-no' : ''}
                            key={path}
                          >
                            {currentRows[index][path]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="footer-wrapper">
            <h4 className="show-rows">
              Showing {indexOfFirstRow === 0 ? '1' : indexOfFirstRow} to{' '}
              {indexOfLastRow} of {tableData.length} Result
            </h4>
          </div>
          {rowsPerPage <= tableData.length && (
            <Pagination
              rowsPerPage={rowsPerPage}
              totalRows={tableData.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          )}
        </div>
        <ConfirmModal
          heading={'Delete User'}
          text={'Are you sure you want to delete this user?'}
          handleClose={handleClose}
          handleSubmit={handleDeleteConfirm}
          visible={visible}
        />
        <ConfirmModal
          heading={'Delete Transcript'}
          text={'Are you sure you want to delete this transcription?'}
          handleClose={handleDeleteTranscriptionClose}
          handleSubmit={handleDeleteTranscriptionConfirm}
          visible={deleteTranscriptVisible}
        />
      </>
    )
  );
}

export default Table;
