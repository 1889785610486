import React from 'react';
import './LoginComponent.css';
import logo from '../../../../Assets/Images/logo.svg';
import Form from '../Form/Form';

function LoginComponent() {
  return (
    <div className="login-container">
      <div className="logo-wrapper">
        <img className="logo" src={logo} alt={"Company's logo"} />
      </div>
      <div className="login-content-wrapper">
        <div className="welcome">
          <p>Welcome back</p>
          <p className="title">Login to your account</p>
        </div>
        <Form />
      </div>
    </div>
  );
}

export default LoginComponent;
