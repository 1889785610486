import { AuthProvider } from './Contexts/AuthContext';
import './Styles/global.css';
import './Styles/bootstrap.css';
import Routes from './Routes/Routes';

function App(props) {
  return (
    <AuthProvider>
      <Routes />
    </AuthProvider>
  );
}

export default App;
