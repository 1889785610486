import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Input from '../../../../Components/Input/Input';
import { AuthContext } from '../../../../Contexts/AuthContext';

import './Form.css';

function Form() {
  const history = useHistory();
  const auth = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await auth.signIn(email, password);
      await (auth.authenticated && history.push('/Dashboard'));
    } catch (err) {
      console.log('error', err);
    }
  };

  return (
    <div className="form-wrapper">
      <form
        className="form-container"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <Input
          label="Email"
          name="Email"
          placeholder=""
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant={'large'}
        />
        <Input
          label="Password"
          name="password"
          placeholder=""
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          variant={'large'}
        />
        <div className="form-error">{auth.error && auth.error.message}</div>
        <div className="signin-container">
          <button className="button-style">Sign in</button>
        </div>
      </form>
    </div>
  );
}

export default Form;
