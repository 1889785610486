import React from 'react';

import './Switch.scss';

const Switch = ({ name, checked, onChange, disabled = false }) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default Switch;
