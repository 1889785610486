import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import ls from 'local-storage';
import { AuthContext } from '../../Contexts/AuthContext';
import Table from '../../Components/Table/Table';
import Sidebar from '../../Components/Sidebar/Sidebar';
import { SIDEBAR_OPTIONS } from '../../Constants/constants';
import Topbar from '../../Components/Topbar/Topbar';
import Button from '../../Components/Button/Button';
import UploadModal from '../../Components/Table/Modals/UploadModal/UploadModal';
import Loader from '../../Components/Loader/Loader';
import './Transcriptions.css';

const parentUrl = process.env.REACT_APP_API_URL;

function Transcriptions(props) {
  // const data = props.location.state.switchValue;
  const { isAdmin } = useContext(AuthContext);
  const [transcriptions, setTranscriptions] = useState([]);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUploadModalClose = () => setUploadModalVisible(false);

  const handleUpload = () => {
    setUploadModalVisible(true);
    console.log('clicked on Upload');
  };

  // const [switchValue, setSwitchValue] = useState(data ? data : true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const url = isAdmin
        ? `${parentUrl}transcript/getAll`
        : `${parentUrl}transcript/getAllUnassigned`;
      const reqHeaders = {
        authorization: ls.get('accessToken'),
      };

      const transcriptionResponse = await axios.get(`${url}`, {
        headers: reqHeaders,
      });

      // console.log('res 2', transcriptionResponse.data.data);
      // setFreelancers(freelancersResponse.data.data);
      // setTranscriptions(transcriptionResponse.data.data);

      var transcriptionArray = transcriptionResponse.data.data;

      var temp = [];
      var actionType = null;
      transcriptionArray.forEach((fr, ind) => {
        if (isAdmin) {
          if (
            fr.status === 'unassigned' ||
            fr.status === 'complete' ||
            fr.status === 'assigned'
          ) {
            actionType = 'edit';
          } else {
            actionType = 'view';
          }
        } else {
          if (fr.status === 'unassigned') {
            actionType = 'view';
          } else {
            actionType = 'view-disabled';
          }
        }

        temp.push({
          id: ind + 1,
          createdOn: fr.uploadTime,
          audioFile: fr.title,
          duration: fr.duration ? fr.duration.toFixed(2) : 0, // for now
          assignedTo: fr.assignedToName || 'Not Assigned',
          status: fr.status,
          action: actionType,
          _id: fr._id,
          channelsArray: fr.channelsArray ? fr.channelsArray : null,
          // data: fr,
        });
      });
      // console.log('temp', temp);
      setTranscriptions(temp);
      setLoading(false);
      // console.log('saved 1', transcriptions);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  const headers = [
    { path: 'id', title: 'No' },
    { path: 'audioFile', title: 'Audio File Name' },
    { path: 'duration', title: 'Duration' },
    { path: 'createdOn', title: 'Created On' },
    { path: 'assignedTo', title: 'Assigned To' },
    { path: 'status', title: 'Status' },
    { path: 'action', title: 'Action' },
  ];

  return (
    <div className="dashboard-container">
      <Topbar title="Dashboard" subtitle="Transcriptions">
        {' '}
        {isAdmin && (
          <Button
            label={'Upload Audio'}
            // variant={'outline'}
            onClick={handleUpload}
          />
        )}
      </Topbar>
      <Sidebar options={SIDEBAR_OPTIONS} />
      {!loading ? (
        transcriptions.length !== 0 ? (
          <Table
            header={headers}
            // data={transcriptions.length ? transcriptions : placeholderData.data}
            data={transcriptions.length && transcriptions}
            tableType={'transcriptions'}
            isAdmin={isAdmin}
            reloadTranscriptions={fetchData}
          />
        ) : (
          <div className="view-none-container">
            <div className="view-none">No Transcriptions added yet.</div>
          </div>
        )
      ) : (
        <Loader />
      )}
      {isAdmin && (
        <UploadModal
          visible={uploadModalVisible}
          reload={fetchData}
          handleClose={handleUploadModalClose}
        />
      )}
    </div>
  );
}

export default Transcriptions;
