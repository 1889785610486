import React, { useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDropzone } from 'react-dropzone';
import Button from '../../../Button/Button';
import axios from 'axios';
import ls from 'local-storage';
import Input from '../../../Input/Input';
import './UploadModal.css';
import Loader from '../../../Loader/Loader';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as OpenIcon } from '../../../../Assets/Icons/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../Assets/Icons/chevron-up.svg';

const UploadModal = ({ visible, handleClose, reload }) => {
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState({ type: 'en', label: 'English' });
  const [open, setOpen] = useState(false);

  const languageList = [
    {
      type: 'en',
      label: 'English',
    },
    {
      type: 'fr',
      label: 'French',
    },
    { type: 'es', label: 'Spanish' },
  ];

  const parentUrl = process.env.REACT_APP_API_URL;
  const handleUpload = async () => {
    setLoading(true);
    try {
      let data = new FormData();

      data.append('title', fileName);
      data.append('recording', file[0]);
      data.append('languageCode', language.type);

      const reqHeaders = {
        authorization: ls.get('accessToken'),
      };

      var response = await axios.post(`${parentUrl}transcript/addNew`, data, {
        headers: reqHeaders,
      });
      // console.log('status', response.status);
      // console.log('data', response.data);
      if (response.data.statusCode === 200) {
        setLoading(false);
        setFile(null);
        setFileName('');
        alert(response.data.message);
        handleClose();
        reload();
      }
    } catch (err) {
      setLoading(false);
      console.log('error', err);
    }
  };

  const onDrop = (acceptedFile) => {
    //access file dropped.
    setFile(acceptedFile);

    var input = acceptedFile[0].name; //gets File name with extension
    var output = input.substr(0, input.lastIndexOf('.')) || input; //remove extension

    if (!fileName) setFileName(output);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    handleUpload,
    maxFiles: 1,
    accept: 'audio/*',
  });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div>
      <Modal
        show={visible}
        onHide={handleClose}
        dialogClassName="transcription-modal-container"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Audio File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="loader-container">
              <Loader label={'Uploading...'} />
            </div>
          ) : null}
          <div className="transcription-form-wrapper">
            <Input
              label={'Audio Clip Title'}
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
            />
            <section className="drop-container">
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p className="drop-text">
                  Click to add files or drag-n-drop them here
                </p>
              </div>
              <aside>
                {acceptedFiles.length !== 0 && <h3>Files</h3>}
                <ul>{acceptedFileItems}</ul>
              </aside>
              <div
                className={`drop-language-select-container ${
                  loading && 'disabled'
                }`}
              >
                <div className="drop-language-text">Select Language</div>
                <div className="drop-language">
                  <Dropdown>
                    <Dropdown.Toggle className={'dropdown-btn'}>
                      <div
                        className="language-label-wrapper"
                        onClick={() => setOpen((o) => !o)}
                      >
                        <div className="language-label">{language.label}</div>
                        <div className="language-label-icon">
                          {!open ? <OpenIcon /> : <CloseIcon />}
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {languageList.map((lang) => {
                        return (
                          <Dropdown.Item
                            onClick={() =>
                              setLanguage({
                                type: lang.type,
                                label: lang.label,
                              })
                            }
                            key={lang.type}
                          >
                            <div className="language-inner-label">
                              {lang.label}
                            </div>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </section>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button label={'Close'} variant={'outline'} onClick={handleClose} />
          <Button
            label={'Upload'}
            // variant={'outline'}
            onClick={handleUpload}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UploadModal;
