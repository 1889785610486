import React from 'react';
import View from '../../Assets/Icons/view.svg';
import Add from '../../Assets/Icons/add.svg';
import Delete from '../../Assets/Icons/delete.svg';
import Edit from '../../Assets/Icons/edit.svg';
import './ActionButton.css';

const ActionButton = ({ type, onClick }) => {
  var clsName = '';
  var component = '';

  switch (type) {
    case 'view':
      clsName = 'view';
      component = View;
      break;

    case 'edit':
      clsName = 'edit';
      component = Edit;
      break;

    case 'edit-disabled':
      clsName = 'edit disabled';
      component = Edit;
      break;

    case 'delete':
      clsName = 'delete';
      component = Delete;
      break;
    case 'add':
      clsName = 'add';
      component = Add;
      break;

    default:
      clsName = 'view disabled';
      component = View;
      break;
  }

  return (
    <div className={`action-style ${clsName}`} onClick={onClick}>
      <img className="icon-container" src={component} alt={type} />
    </div>
  );
};

export default ActionButton;
