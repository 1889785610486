import React, { createContext, useState, useEffect } from 'react';
import ls from 'local-storage';
import jwt_decode from 'jwt-decode';
import axios from 'axios';

export const AuthContext = createContext();

const parentUrl = process.env.REACT_APP_API_URL; //http://localhost:5000/

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState();
  const [isAdmin, setAdmin] = useState(false);

  useEffect(() => {
    if (ls.get('accessToken')) {
      setAuthenticated(true);
      setCurrentUser(ls.get('accessToken'));
      var { name, email, user_id } = jwt_decode(ls.get('accessToken'));

      setCurrentUser({ name: name, email: email, userId: user_id });

      setAdmin(jwt_decode(ls.get('accessToken')).role === 'admin');
      // console.log('token', ls.get('accessToken'));
    }
    setLoading(false);
  }, []);

  const signIn = async (email, password) => {
    //SIGN IN
    if (!email || !password) {
      alert('Please enter your email and password');
      return;
    } else {
      // console.log(email, password);
      try {
        var response = await axios.post(`${parentUrl}users/signin`, {
          email: email,
          password: password,
        });
        // console.log('status', response.status);
        // console.log('data', response);
        if (response.data.statusCode === 200) {
          ls.set('accessToken', response.data.data.accessToken);
          ls.set('refreshToken', response.data.data.refreshToken);
          setAdmin(jwt_decode(ls.get('accessToken')).role === 'admin');

          var { name, user_id } = jwt_decode(ls.get('accessToken'));

          setCurrentUser({ name: name, email: email, userId: user_id });

          window.location =
            window.location.protocol +
            '//' +
            window.location.host +
            '/Dashboard';
        }
      } catch (err) {
        setError(err.response.data);
        console.log('err', err.response.data);
        // console.log('response', response);
        // console.log('response data', response.data);
      }
    }
  };

  const signOut = () => {
    //SIGN OUT
    try {
      console.log('signing out');
      if (ls.get('accessToken')) {
        ls.remove('accessToken');
      }
      setCurrentUser({});
      setAuthenticated(false);
      if (isAdmin) {
        setAdmin(false);
      }
    } catch (err) {
      setError(err);
      console.log('error', err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        loading,
        authenticated,
        error,
        isAdmin,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
