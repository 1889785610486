import { Link } from 'react-router-dom';
import './Sidebar.scss';
import { ReactComponent as Logo } from '../../Assets/Icons/ringover.svg';
import { ReactComponent as Logout } from '../../Assets/Icons/log-out.svg';
import defaultUser from '../../Assets/Icons/default-user.svg';

import { ReactComponent as Wave } from '../../Assets/Icons/wave.svg';
import { ReactComponent as People } from '../../Assets/Icons/people.svg';

import { useContext } from 'react';
import { AuthContext } from '../../Contexts/AuthContext';

const Sidebar = ({ className }) => {
  const { isAdmin, signOut } = useContext(AuthContext);

  const options = [
    {
      icon: <Wave />,
      link: isAdmin ? '/Transcriptions' : '/Dashboard',
      name: 'transcription',
    },
    isAdmin && {
      icon: <People />,
      link: '/Dashboard',
      name: 'freelancers',
    },
  ];

  // console.log('window.location.pathname', window.location.pathname);

  return (
    <div className={`sidebar ${className}`}>
      <div className="logo">
        <Link to="/Dashboard">
          <Logo />
        </Link>
      </div>
      <div className="options">
        {options.map(({ link, name, icon }, index) => {
          // console.log(window.location.pathname, link, name);
          return (
            <Link
              key={index}
              to={link}
              className={window.location.pathname === link ? 'active' : ''}
            >
              {icon}
            </Link>
          );
        })}
      </div>

      <div className="bottom-options">
        <div className="profile">
          <Link to="/Profile">
            <img
              src={defaultUser}
              alt={"user's profile"}
              className="user-img"
            />
          </Link>
        </div>
        <Logout onClick={signOut} />
      </div>
    </div>
  );
};

export default Sidebar;
