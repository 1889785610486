/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
  forwardRef,
  useContext,
  useImperativeHandle,
} from 'react';
import { WaveSurfer, WaveForm, Region } from 'wavesurfer-react';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min';
import Collapse from 'react-bootstrap/Collapse';
import Dropdown from 'react-bootstrap/Dropdown';
import { WithContext as ReactTags } from 'react-tag-input';

import CloseIcon from '../../../../Assets/Icons/close.svg';
import Button from '../../../../Components/Button/Button';
import { ReactComponent as PlayIcon } from '../../../../Assets/Icons/play-btn.svg';
import { ReactComponent as SpeedIcon } from '../../../../Assets/Icons/speed-rate.svg';
import { ReactComponent as ForwardIcon } from '../../../../Assets/Icons/seek-fwd.svg';
import { ReactComponent as RewindIcon } from '../../../../Assets/Icons/seek-rev.svg';
import { ReactComponent as PauseIcon } from '../../../../Assets/Icons/pause-btn.svg';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import Loader from '../../../../Components/Loader/Loader';

import './AudioPlayer.css';

import { AuthContext } from '../../../../Contexts/AuthContext';

const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

const AudioPlayer = forwardRef(
  (
    {
      source,
      channel,
      playAll,
      playOne,
      playbackRate,
      skipFwd,
      skipBack,
      key,
      keyId,
      setLoading,
      addTranscription,
      getRegionData,
      isCompleted,
    },
    ref
  ) => {
    const rates = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

    const { width } = useWindowDimensions();

    const [timelineVisible] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [text, setText] = useState('');
    const [tags, setTags] = useState([]);
    const [speed, setSpeed] = useState(1);
    const [interaction, setInteraction] = useState(true);
    const [playStart, setPlayStart] = useState(0);
    const [regions, setRegions] = useState([]);
    const [open, setOpen] = useState(false);
    const [xPos, setXPos] = useState(0);
    const [yPos, setYPos] = useState(0);
    const [waveLoader, setWaveLoader] = useState(true);
    const regionsRef = useRef(regions);

    const { isAdmin } = useContext(AuthContext);

    const formatTime = useCallback((seconds, pxPerSec) => {
      seconds = Number(seconds);
      var minutes = Math.floor(seconds / 60);
      seconds = seconds % 60;
      var secondsStr = Math.round(seconds).toString();
      if (pxPerSec >= 25 * 10) {
        secondsStr = seconds.toFixed(2);
      } else if (pxPerSec >= 25 * 1) {
        secondsStr = seconds.toFixed(1);
      }

      if (minutes > 0) {
        if (seconds < 10) {
          secondsStr = '0' + secondsStr;
        }
        return `${minutes}:${secondsStr}`;
      }
      return `${secondsStr}s`;
    }, []);

    function timeInterval(pxPerSec) {
      var retval = 1;
      if (pxPerSec >= 25 * 100) {
        retval = 0.01;
      } else if (pxPerSec >= 25 * 40) {
        retval = 0.025;
      } else if (pxPerSec >= 25 * 10) {
        retval = 0.1;
      } else if (pxPerSec >= 25 * 4) {
        retval = 0.25;
      } else if (pxPerSec >= 25) {
        retval = 2;
      } else if (pxPerSec * 5 >= 25) {
        retval = 5;
      } else if (pxPerSec * 15 >= 25) {
        retval = 15;
      } else {
        retval = Math.ceil(0.5 / pxPerSec) * 60;
      }
      return retval;
    }

    // function primaryLabelInterval(pxPerSec) {
    //   var retval = 1;
    //   if (pxPerSec >= 25 * 100) {
    //     retval = 10;
    //   } else if (pxPerSec >= 25 * 40) {
    //     retval = 4;
    //   } else if (pxPerSec >= 25 * 10) {
    //     retval = 10;
    //   } else if (pxPerSec >= 25 * 4) {
    //     retval = 4;
    //   } else if (pxPerSec >= 25) {
    //     retval = 1;
    //   } else if (pxPerSec * 5 >= 25) {
    //     retval = 5;
    //   } else if (pxPerSec * 15 >= 25) {
    //     retval = 15;
    //   } else {
    //     retval = Math.ceil(10 / pxPerSec) * 60;
    //   }
    //   return retval;
    // }

    const plugins = useMemo(() => {
      return [
        {
          plugin: RegionsPlugin,
          options: {
            dragSelection: true,
            loop: true,
            mediaControls: true,
            color: 'rgba(64, 224, 207, 0.1)',
          },
        },
        timelineVisible && {
          plugin: TimelinePlugin,
          options: {
            container: '#timeline',
            timeInterval: timeInterval,
            height: 25,
            notchPercentHeight: 50,
            // primaryLabelInterval: primaryLabelInterval,
            // secondaryLabelInterval: secondaryLabelInterval,
            secondaryFontColor: 'rgba(122, 122, 122, 1)',
            formatTimeCallback: formatTime,
          },
        },
      ].filter(Boolean);
    }, [timelineVisible, formatTime]);

    useEffect(() => {
      regionsRef.current = regions;
    }, [regions]);

    useEffect(() => {
      try {
        if (playAll) {
          wavesurferRef.current.backend.ac.resume();
          wavesurferRef.current.play(0);
        } else {
          wavesurferRef.current.backend.ac.resume();
          wavesurferRef.current.pause();

          if (playOne === keyId) {
            wavesurferRef.current.backend.ac.resume();
            wavesurferRef.current.play();
          } else {
            wavesurferRef.current.backend.ac.resume();
            wavesurferRef.current.pause();
          }
        }
      } catch (err) {
        console.log('err', err);
      }
    }, [playAll, playOne]);

    useEffect(() => {
      if (skipFwd) {
        console.log('skipped forward');
        handleSkip(5);
      } else if (skipBack) {
        console.log('skipped backward');
        handleSkip(-5);
      }
    }, [skipFwd, skipBack]);

    // const canCreateRegion = useCallback(
    //   (region) => {
    //     console.log('channel', channel);
    //     channel.partsArray.every((note) => {
    //       console.log('note', note);
    //       console.log(
    //         ` note.start : ${note.start} start: ${region.start} note.end : ${
    //           note.end
    //         } end: ${region.end}, limit : ${note.end - note.start}`
    //       );
    //       if (note.type === 'empty') {
    //         console.log('inside empty note');
    //         return true;
    //       } else if (
    //         note.start === start ||
    //         note.end === end ||
    //         (note.start >= region.start && note.start <= region.end) ||
    //         (note.end <= region.end && note.end >= region.start)
    //       ) {
    //         console.log('in this');
    //         alert('You cannot create a region here!');
    //         setRegions([]);
    //         return false;
    //       } else {
    //         return true;
    //       }
    //     });
    //   },
    //   [channel, end, start]
    // );

    useEffect(() => {
      try {
        setSpeed(playbackRate);
        wavesurferRef.current.setPlaybackRate(playbackRate);
      } catch (err) {
        console.log('err', err);
      }
    }, [playbackRate]);

    const regionCreatedHandler = useCallback((region) => {
      // console.log('region-created --> region:', region);

      // console.log('region data', region.data);

      if (region.data.systemRegionId) return;

      setRegions(region);
    }, []);

    const wavesurferRef = useRef();

    const handleWSMount = useCallback(
      (waveSurfer) => {
        wavesurferRef.current = waveSurfer;
        if (wavesurferRef.current) {
          wavesurferRef.current.setWaveColor('#c9c9c9');
          wavesurferRef.current.setCursorColor('#ff754a');
          wavesurferRef.current.setProgressColor('#ff754a');
          wavesurferRef.current.setPlaybackRate(speed);
          wavesurferRef.current.toggleInteraction();
          console.log('source', source);
          wavesurferRef.current.load(source);

          wavesurferRef.current.on('region-created', regionCreatedHandler);

          wavesurferRef.current.on('ready', () => {
            console.log('WaveSurfer is ready');
            setLoading(false);
            setWaveLoader(false);
          });

          wavesurferRef.current.on('region-removed', (region) => {
            // console.log('region-removed --> ', region);
          });
          wavesurferRef.current.on('loading', (data) => {
            // console.log('loading --> ', data);
          });

          wavesurferRef.current.on('error', (err) => {
            console.log('err --> ', err);
          });

          if (window) {
            window.surferidze = wavesurferRef.current;
          }
        }
      },
      [regionCreatedHandler, source, speed, setLoading]
    );

    useEffect(() => {
      return () => {
        wavesurferRef.current.destroy();
      };
    }, []);

    const removeLastRegion = useCallback(() => {
      setRegions([]);
      wavesurferRef.current.pause();
    }, []);

    const handleRegionUpdateEnd = useCallback((region, smth) => {
      // console.log('region-update-end --> region:', region);
      // console.log('touched', smth);
      // canCreateRegion(region);
      setStart(region.start);
      setPlayStart(region.start);
      setEnd(region.end);
      // const duration = wavesurferRef.current.getDuration();
      // const progress = region.start / duration;
      // console.log('progress', progress);
      wavesurferRef.current.play(region.start);
      wavesurferRef.current.pause();
      // checkInteraction();
    }, []);

    // const checkInteraction = () => {
    //   if(interaction === true)
    //   {
    //   wavesurferRef.current.toggleInteraction();
    //   setInteraction(i => !i);
    //   }
    // }

    const generateRegion = useCallback(
      (start, end) => {
        if (!wavesurferRef.current) return;

        // console.log('region generation', start, end, regions);
        // const [start, end] = getRegionData();

        if (regions && regions.id === `region-${start}-${end}`) {
          removeLastRegion();
          wavesurferRef.current.pause(0);
        } else {
          setRegions({
            id: `region-${start}-${end}`,
            start: start,
            end: end,
            color: `rgba(64, 224, 207, 0.1)`,
            loop: true,
          });
          setPlayStart(start);
          wavesurferRef.current.play(start);
        }
      },
      [regions, wavesurferRef, removeLastRegion]
    );

    const checkRegion = useCallback(() => {
      if (regions) {
        return true;
      } else {
        return false;
      }
    }, [regions]);

    useImperativeHandle(ref, () => ({
      generateRegion: generateRegion,
      checkRegion: checkRegion,
      removeLastRegion: removeLastRegion,
    }));

    const play = () => {
      setIsPlaying(!isPlaying);
      // console.log('start', playStart);
      try {
        if (!isPlaying) {
          wavesurferRef.current.backend.ac.resume();
          wavesurferRef.current.play(playStart);
        } else {
          wavesurferRef.current.pause();
        }
      } catch (err) {
        console.log('err', err);
      }
    };

    // const regionPlay = useCallback(() => {
    //   regionsRef.current.playLoop();
    // }, [regions]);

    const handleDoubleClick = useCallback((region, smth) => {
      if (!isCompleted || isAdmin) {
        // console.log('region-update-end --> region:', region);
        // console.log('double', smth, smth.clientX, smth.clientY);
        // console.log(`x ${x} y ${y}`)
        if (smth.clientX + 450 >= width) {
          setXPos(smth.clientX - 400);
          setYPos(smth.clientY);
        } else {
          setXPos(smth.clientX);
          setYPos(smth.clientY);
        }
        // setOpen(!open);
        setOpen((o) => !o);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRateChange = (rate) => {
      setSpeed(rate);
      wavesurferRef.current.setPlaybackRate(rate);
    };

    const handleSkip = (offset) => {
      wavesurferRef.current.skip(offset);
    };

    const handleClose = () => {
      setOpen(false);
      setInteraction(true);
      setTags([]);
      console.log(interaction);
      // wavesurferRef.current.toggleInteraction();
    };

    const handleAddTranscription = () => {
      addTranscription(channel._id, text, start, end, tags);
      regionsRef.current.onResize(end - start, 'start');
      regionsRef.current.onResize(end - start);
      setStart(regionsRef.current.start);
      setEnd(regionsRef.current.end);
      wavesurferRef.current.play(regionsRef.current.start);
      wavesurferRef.current.pause();
      setText('');
      setTags([]);
    };
    // let xhr = {
    //   cache: 'default',
    //   mode: 'cors',
    //   method: 'GET',
    //   credentials: 'same-origin',
    //   // credentials: 'include',
    //   // redirect: 'follow',
    //   // referrer: 'client',
    //   // headers: [{ key: 'cache-control', value: 'no-cache' }],
    // };

    const handleTagDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };

    const handleTagAdd = (tag) => {
      setTags((prevTags) => [...prevTags, tag.text]);
    };

    const handleTagDrag = (tag, currPos, newPos) => {
      const newTags = [...tags];
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
      setTags(newTags.map((tag) => (tag.text ? tag.text : tag)));
    };

    const handleTagClick = (index) => {
      console.log('The tag at index ' + index + ' was clicked');
    };

    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    return (
      <>
        <div className={'audio-player-container'} key={key}>
          {waveLoader && (
            <div className={'waveform-loader'}>
              <Loader type={'text'} label={'Preparing Audio ...'} />
            </div>
          )}
          <WaveSurfer plugins={plugins} onMount={handleWSMount}>
            <WaveForm
              id="waveform"
              scrollParent={false}
              barHeight={2}
              height={50}
              responsive={true}
              // interact={false}
              // xhr={xhr}
              // partialRender={true}
              // audioContext={audioContext}
            >
              <Region
                onUpdateEnd={handleRegionUpdateEnd}
                key={regions.id}
                onDoubleClick={handleDoubleClick}
                {...regions}
              />
            </WaveForm>
            <div id="timeline" />
          </WaveSurfer>
        </div>
        <Collapse in={open} dimension="width">
          <div>
            <div
              className="transcription-text"
              style={{ position: 'absolute', left: xPos, top: yPos + 50 }}
            >
              <img
                className="btn-close"
                src={CloseIcon}
                alt={'close button'}
                onClick={handleClose}
              />
              <div>
                <ReactTags
                  tags={tags?.map((tag) => ({
                    id: tag,
                    text: tag,
                  }))}
                  handleDelete={handleTagDelete}
                  handleAddition={handleTagAdd}
                  handleDrag={handleTagDrag}
                  handleTagClick={handleTagClick}
                  onTagUpdate={onTagUpdate}
                  delimiters={delimiters}
                  placeholder="Add tag"
                  allowDeleteFromEmptyInput={true}
                  autocomplete={true}
                  readOnly={false}
                  allowUnique={true}
                  allowDragDrop={true}
                  inline={true}
                  editable={true}
                />
              </div>
              <div className="text-area-container">
                <textarea
                  className="text-area"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder={'Start typing...'}
                />
              </div>
              <div className="collapse-bottom-container">
                <div className="media-controls">
                  <div className="rev" onClick={() => handleSkip(-5)}>
                    <RewindIcon />
                  </div>
                  <div className="play" onClick={play}>
                    {!isPlaying ? <PlayIcon /> : <PauseIcon />}
                  </div>
                  <div className="fwd" onClick={() => handleSkip(5)}>
                    <ForwardIcon />
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle className="dropdown-btn">
                      <div className="speed">
                        <SpeedIcon />
                        <div className="speed-rate">
                          {speed === 1 ? 'Normal' : speed + 'x'}
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {rates.map((rate, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => handleRateChange(rate)}
                            key={index}
                          >
                            {rate}x
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <Button
                  label={'Add Note'}
                  variant={'primary'}
                  onClick={handleAddTranscription}
                />
              </div>
            </div>
          </div>
        </Collapse>
      </>
    );
  }
);

export default AudioPlayer;
