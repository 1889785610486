import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import ls from 'local-storage';
import { AuthContext } from '../../Contexts/AuthContext';
// import placeholderData from './placeholderData.json';
// import myTranscriptionsData from './transcriptionData.json';
import Table from '../../Components/Table/Table';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Topbar from '../../Components/Topbar/Topbar';
import { SIDEBAR_OPTIONS } from '../../Constants/constants';
import Switch from '../../Components/Switch/Switch';
import Button from '../../Components/Button/Button';
import FreelancerModal from '../../Components/Table/Modals/FreelancerModal/FreelancerModal';
import Loader from '../../Components/Loader/Loader';

import './Dashboard.scss';

const parentUrl = process.env.REACT_APP_API_URL;

function Dashboard() {
  const { isAdmin, currentUser } = useContext(AuthContext);
  // const [user, setUser] = useState([]);

  const [transcriptions, setTranscriptions] = useState([]);
  const [freelancers, setFreelancers] = useState([]);
  const [switchValue, setSwitchValue] = useState(false);
  const [freelancerModalVisible, setfreelancerModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAdd = () => {
    setfreelancerModalVisible(true);
  };

  const handleFreelancerModalClose = () => setfreelancerModalVisible(false);

  const fetchFreelancers = async () => {
    try {
      setLoading(true);
      const reqHeaders = {
        authorization: ls.get('accessToken'),
      };
      if (isAdmin) {
        const freelancersPromise = axios.get(
          `${parentUrl}users/getAllFreelancers`,
          { headers: reqHeaders }
        );

        const freelancersResponse = await Promise.resolve(freelancersPromise);

        var freelancersArray = freelancersResponse.data.data;

        var temp = [];
        freelancersArray.forEach((fr, ind) => {
          var [firstName, lastName] = fr.name.split(' ');
          var registeredOn = fr.registeredOn;
          var transcriptionsCompleted =
            fr.completedTranscriptsNumber.toString() +
            ' / ' +
            fr.assignedTranscriptsNumber.toString();

          temp.push({
            id: ind + 1,
            email: fr.email,
            firstName: firstName,
            lastName: lastName,
            registeredOn: registeredOn,
            transCompleted: transcriptionsCompleted,
            status: 'verified',
            action: 'delete',
            _id: fr._id,
          });
        });
        setFreelancers(temp);
        setLoading(false);
        // console.log('saved 2', freelancers);
      }
    } catch (err) {
      console.log('error', err);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchTranscriptions = async () => {
      try {
        setLoading(true);

        const url = switchValue
          ? `${parentUrl}transcript/getAllUnassigned`
          : `${parentUrl}transcript/getMyTranscripts`;

        const reqHeaders = {
          authorization: ls.get('accessToken'),
        };

        const transcriptionPromise = axios.get(`${url}`, {
          headers: reqHeaders,
        });

        const transcriptionResponse = await Promise.resolve(
          transcriptionPromise
        );

        // console.log('res 2', transcriptionResponse.data);

        var transcriptionArray = transcriptionResponse.data.data;

        var temp2 = [];
        transcriptionArray.forEach((fr, ind) => {
          var actionType = null;
          // console.log('user',fr.assignedToName, user.name);z!
          if (
            fr.status === 'unassigned' ||
            fr.assignedTo === currentUser.userId
          ) {
            actionType = 'view';
          } else {
            actionType = 'view-disabled';
          }
          temp2.push({
            id: ind + 1,
            createdOn: fr.uploadTime,
            title: fr.title,
            duration: fr.duration ? fr.duration.toFixed(2) : 0, // for now
            assignedTo: fr.assignedToName || 'Not Assigned',
            status: fr.status,
            action: actionType,
            _id: fr._id,
          });
        });

        setTranscriptions(temp2);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    try {
      fetchFreelancers();
      fetchTranscriptions();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchValue, isAdmin]);

  const headers = isAdmin
    ? [
        { path: 'id', title: 'No' },
        { path: 'email', title: 'Email' },
        { path: 'firstName', title: 'First Name' },
        { path: 'lastName', title: 'Last Name' },
        { path: 'registeredOn', title: 'Registered On' },
        { path: 'transCompleted', title: 'Transcriptions Completed' },
        { path: 'status', title: 'Status' },
        { path: 'action', title: 'Action' },
      ]
    : [
        { path: 'id', title: 'No' },
        { path: 'title', title: 'Audio File Name' },
        { path: 'duration', title: 'Duration' },
        { path: 'createdOn', title: 'Created On' },
        { path: 'assignedTo', title: 'Assigned To' },
        { path: 'status', title: 'Status' },
        { path: 'action', title: 'Action' },
      ];

  // const tableData = isAdmin
  //   ? freelancers.length
  //     ? freelancers
  //     : placeholderData.data
  //   : transcriptions.length
  //   ? transcriptions
  //   : myTranscriptionsData.data;

  const tableData = isAdmin
    ? freelancers.length
      ? freelancers
      : null
    : transcriptions.length
    ? transcriptions
    : null;

  const tableName = isAdmin ? 'Freelancers' : 'My Transcriptions';
  const tableType = isAdmin ? 'freelancers' : 'transcriptions';
  // const tableName ='My Transcriptions';
  // const tableType ='transcriptions';

  return (
    <>
      <div className="dashboard-container">
        <Topbar
          title="Dashboard"
          subtitle={isAdmin ? 'Freelancers' : 'Your audio clips'}
        >
          {' '}
          {isAdmin && (
            <Button
              label={'Add Freelancers'}
              // variant={'outline'}
              onClick={handleAdd}
            />
          )}
          {!isAdmin && (
            <span className="topbar-action-tag">
              {switchValue ? 'Show All' : 'Only Mine'}
            </span>
          )}{' '}
          {!isAdmin && (
            <Switch
              name={switchValue ? 'Show All' : 'Only Mine'}
              checked={switchValue}
              onChange={() => {
                setSwitchValue(!switchValue);
              }}
            />
          )}
        </Topbar>
        <Sidebar options={SIDEBAR_OPTIONS} />
        {!loading ? (
          tableData ? (
            <Table
              header={headers}
              data={tableData}
              tableName={tableName}
              tableType={tableType}
              isAdmin={isAdmin}
              reloadFreelancers={fetchFreelancers}
            />
          ) : (
            <div className="view-none-container">
              <div className="view-none">
                {tableType === 'freelancers'
                  ? 'No Freelancers added yet.'
                  : switchValue
                  ? 'No Transcriptions Available.'
                  : 'No Transcriptions Assigned.'}
              </div>
            </div>
          )
        ) : (
          <Loader />
        )}
        {isAdmin && (
          <FreelancerModal
            visible={freelancerModalVisible}
            reload={fetchFreelancers}
            handleClose={handleFreelancerModalClose}
          />
        )}
      </div>
    </>
  );
}

export default Dashboard;
