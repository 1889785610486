/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import ls from 'local-storage';

import Button from '../../Components/Button/Button';
import AudioPlayer from './components/AudioPlayer/AudioPlayer';
import Topbar from '../../Components/Topbar/Topbar';
import {
  convertDuration,
  calculateTimeFormat,
} from '../../utils/timeFormatter';
import { KEYCODES } from './utils';

import { ReactComponent as PlayIcon } from '../../Assets/Icons/play-btn.svg';
import { ReactComponent as SpeedIcon } from '../../Assets/Icons/speed-rate.svg';
import { ReactComponent as ForwardIcon } from '../../Assets/Icons/seek-fwd.svg';
import { ReactComponent as RewindIcon } from '../../Assets/Icons/seek-rev.svg';
import { ReactComponent as PauseIcon } from '../../Assets/Icons/pause-btn.svg';
import { ReactComponent as ViewIcon } from '../../Assets/Icons/view.svg';
import { ReactComponent as CloseIcon } from '../../Assets/Icons/close.svg';
import Loader from '../../Components/Loader/Loader';

import Dropdown from 'react-bootstrap/Dropdown';

import { AuthContext } from '../../Contexts/AuthContext';

import Sidebar from '../../Components/Sidebar/Sidebar';
import { SIDEBAR_OPTIONS } from '../../Constants/constants';
import TranscriptionView from './components/TranscriptionView/TranscriptionView';
import ConfirmModal from '../../Components/ConfirmModal/ConfirmModal';

import './AudioTranscription.css';

const parentUrl = process.env.REACT_APP_API_URL;

function AudioTranscription(props) {
  const { isAdmin } = useContext(AuthContext);

  const transcription = props.location.state.transcription;

  const AudioRef = useRef([]);
  const TranscriptionViewRef = useRef();

  const rates = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
  const [speed, setSpeed] = useState(1);
  const [transcript, setTranscript] = useState([]);
  const [playAll, setPlayAll] = useState(false);
  const [playOne, setPlayOne] = useState(-1);
  const [isAssigned, setAssigned] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [skipFwd, setSkipFwd] = useState(false);
  const [skipBack, setSkipBack] = useState(false);
  const [channels, setChannels] = useState([]);
  const [channelsList, setChannelsList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [assignVisible, setAssignVisible] = useState(false);
  const [markAsCompletedVisible, setMarkAsCompletedVisible] = useState(false);
  const [activeSegment, setActiveSegment] = useState(null);
  const [showKeyboardShortcuts, setShowKeyboardShortcuts] = useState(false);

  useEffect(() => {
    if (channels.length > 1) {
      setActiveSegment({
        channel: 1,
        part: 0,
      });
    } else {
      setActiveSegment({
        channel: 0,
        part: 0,
      });
    }
  }, [channels]);

  useEffect(() => {
    const findNextSegment = (activeChannel, currentPart) => {
      for (let i = currentPart + 1; i < activeChannel.partsArray.length; i++) {
        if (activeChannel.partsArray[i].type !== 'empty') {
          return i;
        }
      }
      return currentPart;
    };

    const findPreviousSegment = (activeChannel, currentPart) => {
      for (let i = currentPart - 1; i >= 0; i--) {
        if (activeChannel.partsArray[i].type !== 'empty') {
          return i;
        }
      }
      return currentPart;
    };

    const checkKeyDown = (e) => {
      if (!activeSegment || !channelsList || channelsList.length <= 0) return;
      if (e.target.tagName.toLowerCase() !== 'body') return;

      const activeChannel = channelsList[activeSegment.channel];

      switch (e.keyCode) {
        case KEYCODES.upArrow:
          // up arrow
          e.preventDefault();
          if (activeSegment.part > 0) {
            setActiveSegment((prev) => ({
              ...prev,
              part: findPreviousSegment(activeChannel, prev.part),
            }));
          }
          break;

        case KEYCODES.downArrow:
          // down arrow
          e.preventDefault();
          if (activeSegment.part < activeChannel.partsArray.length - 1) {
            setActiveSegment((prev) => ({
              ...prev,
              part: findNextSegment(activeChannel, prev.part),
            }));
          }
          break;

        case KEYCODES.leftArrow:
          // left arrow
          e.preventDefault();
          if (
            activeChannel.channelNumber !== 0 &&
            activeChannel.channelNumber !== 1
          ) {
            setActiveSegment((prev) => ({
              ...prev,
              channel: prev.channel - 1,
              part:
                findNextSegment(channelsList[prev.channel - 1], prev.part) ===
                prev.part
                  ? findPreviousSegment(
                      channelsList[prev.channel - 1],
                      prev.part
                    )
                  : findNextSegment(channelsList[prev.channel - 1], prev.part),
            }));
          }
          break;

        case KEYCODES.rightArrow:
          // right arrow
          e.preventDefault();
          if (
            activeChannel.channelNumber !== 0 &&
            activeChannel.channelNumber < channels.length - 1
          ) {
            setActiveSegment((prev) => ({
              ...prev,
              channel: prev.channel + 1,
              part:
                findNextSegment(channelsList[prev.channel + 1], prev.part) ===
                prev.part
                  ? findPreviousSegment(
                      channelsList[prev.channel + 1],
                      prev.part
                    )
                  : findNextSegment(channelsList[prev.channel + 1], prev.part),
            }));
          }
          break;

        default:
          return null;
      }
    };

    window.addEventListener('keydown', checkKeyDown);

    return () => {
      window.removeEventListener('keydown', checkKeyDown);
    };
  }, [activeSegment, channelsList]);

  const handleAssignClose = () => {
    setAssignVisible(false);
  };

  const handleAssignOpen = () => {
    setAssignVisible(true);
  };

  const handleRateChange = (rate) => {
    setSpeed(rate);
  };

  const handleAssign = async () => {
    try {
      var reqBody = {
        transcriptId: transcript._id,
      };
      var reqHeaders = {
        authorization: ls.get('accessToken'),
      };
      var res = await axios.post(`${parentUrl}transcript/assignToMe`, reqBody, {
        headers: reqHeaders,
      });
      if (res.data.statusCode === 200) {
        setAssigned(true);
        setAssignVisible(false);
      } else {
        console.log(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleMarkAsCompletedClose = () => {
    setMarkAsCompletedVisible(false);
  };

  const openMarkAsCompletedConfirm = () => {
    setMarkAsCompletedVisible(true);
  };

  const handleMarkAsCompleted = async () => {
    try {
      var reqBody = {
        transcriptId: transcript._id,
      };
      var reqHeaders = {
        authorization: ls.get('accessToken'),
      };
      var res = await axios.post(
        `${parentUrl}transcript/markAsComplete`,
        reqBody,
        { headers: reqHeaders }
      );
      if (res.data.statusCode === 200) {
        setCompleted(true);
        setMarkAsCompletedVisible(false);
      } else {
        console.log(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSkipFwd = () => {
    setSkipFwd(true);
    setTimeout(() => {
      setSkipFwd(false);
    }, 1000);
  };

  const handleSkipBehind = () => {
    setSkipBack(true);
    setTimeout(() => {
      setSkipBack(false);
    }, 1000);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const reqHeaders = {
        authorization: ls.get('accessToken'),
      };
      const transcriptResponse = await axios.get(
        `${parentUrl}transcript/getOne/${transcription._id}`,
        { headers: reqHeaders }
      );
      if (transcriptResponse.data.statusCode === 200) {
        const transcriptData = transcriptResponse.data.data;
        const channelsData = transcriptResponse.data.data.channelsArray;
        // channelsData[1].partsArray[0].tags = ['Greetings'];

        setTranscript(transcriptData);
        setChannels(channelsData);
        setAssigned(transcriptData.assignedTo != null);
        setCompleted(transcriptData.status === 'complete');
        setLoading(false);
      }
    } catch (err) {
      console.log('error', err);
      setLoading(false);
    }
  };

  const refetchData = async () => {
    try {
      const reqHeaders = {
        authorization: ls.get('accessToken'),
      };
      const transcriptResponse = await axios.get(
        `${parentUrl}transcript/getOne/${transcription._id}`,
        { headers: reqHeaders }
      );
      if (transcriptResponse.data.statusCode === 200) {
        const channelsData = transcriptResponse.data.data.channelsArray;

        setChannels(channelsData);
        TranscriptionViewRef.current.setOrder();
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const handleClick = (start, end, channel) => {
    if (AudioRef.current[1].checkRegion()) {
      if (AudioRef.current[2] !== undefined) {
        AudioRef.current[2].removeLastRegion();
      }
    }
    if (AudioRef.current[2] && AudioRef.current[2].checkRegion()) {
      if (AudioRef.current[1] !== undefined) {
        AudioRef.current[1].removeLastRegion();
      }
    }

    AudioRef.current[channel].generateRegion(start, end);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlay = () => {
    setPlayAll(!playAll);
  };

  const handlePlayOne = (channelId) => {
    setPlayAll(false);
    setPlayOne(channelId);
  };

  const addTranscription = async (channel, text, start, end, tags) => {
    try {
      const reqHeaders = {
        authorization: ls.get('accessToken'),
      };
      const addTranscriptResponse = await axios.post(
        `${parentUrl}transcript/addPart`,
        {
          channelId: channel,
          newPartsArray: [
            {
              start: start,
              end: end,
              transcript: text,
              tags,
            },
          ],
        },
        { headers: reqHeaders }
      );
      if (addTranscriptResponse.data.statusCode === 200) {
        refetchData();
      }
    } catch (err) {
      console.log('error', err);
    }
    console.log(
      `channel: ${channel} text: ${text} start: ${start} end: ${end} `
    );
  };

  const deleteTranscription = async (partId) => {
    try {
      const reqHeaders = {
        authorization: ls.get('accessToken'),
      };
      const deleteTranscriptResponse = await axios.get(
        `${parentUrl}transcript/deletePart/${partId}`,
        { headers: reqHeaders }
      );
      console.log('delete', deleteTranscriptResponse.data.data);
      if (deleteTranscriptResponse.data.statusCode === 200) {
        refetchData();
        console.log('deleted');
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const editTranscription = async (partId, text, start, end, tags) => {
    try {
      const reqHeaders = {
        authorization: ls.get('accessToken'),
      };
      const addTranscriptResponse = await axios.post(
        `${parentUrl}transcript/updatePart`,
        {
          partId: partId,
          data: {
            transcript: text,
            tags,
          },
        },
        { headers: reqHeaders }
      );
      if (addTranscriptResponse.data.statusCode === 200) {
        refetchData();
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const handleExportJSON = () => {
    const obj = {
      filePath: channels[0].url,
    };
    channels.forEach((channel, i) => {
      if (i > 0) {
        obj[`channel${channel.channelNumber}`] = [];
        channel.partsArray.forEach((part) => {
          if (part.type !== 'empty') {
            obj[`channel${channel.channelNumber}`].push({
              timestampSegmentStart: calculateTimeFormat(part.start),
              timestampSegmentEnd: calculateTimeFormat(part.end),
              segmentContent: part.transcript,
              segmentTag: part.tags?.length > 0 ? part.tags.toString() : '',
            });
          }
        });
      }
    });
    const fileName = transcript.title;
    const json = JSON.stringify(obj);
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const duration = convertDuration(transcription.duration);

  return (
    <>
      {!loading ? (
        <div className="dashboard-container">
          <Topbar
            title={
              transcription.audioFile
                ? transcription.audioFile
                : transcription.title
            }
            subtitle={duration ? duration : ''}
          >
            {' '}
            <div className="user-action-btn">
              <Button
                label="Export as JSON"
                variant="primary"
                onClick={handleExportJSON}
              />
              {!isAdmin ? (
                !isAssigned ? (
                  <div></div>
                ) : !completed ? (
                  <Button
                    label={'Mark as Completed'}
                    variant={'fill-light'}
                    onClick={openMarkAsCompletedConfirm}
                  />
                ) : (
                  <Button label={'Completed'} variant={'fill-green'} />
                )
              ) : null}
            </div>
          </Topbar>
          <Sidebar options={SIDEBAR_OPTIONS} />
          <div
            className={
              isAdmin || isAssigned
                ? 'transcription-container'
                : 'transcription-container disabled-wrapper'
            }
          >
            <div className="audio-transcription-wrapper">
              <div className={'audio-transcription'}>
                <div
                  className={
                    isAdmin || isAssigned
                      ? 'media-controls'
                      : 'media-controls audio-disabled'
                  }
                >
                  <div className={'rev'} onClick={handleSkipBehind}>
                    <RewindIcon />
                  </div>
                  <div className={'play'} onClick={handlePlay}>
                    {!playAll ? <PlayIcon /> : <PauseIcon />}
                  </div>
                  <div className={'fwd'} onClick={handleSkipFwd}>
                    <ForwardIcon />
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle className={'dropdown-btn'}>
                      <div className="speed">
                        <SpeedIcon />
                        <div className="speed-rate">{`${speed}x`}</div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {rates.map((rate, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => handleRateChange(rate)}
                            key={index}
                          >
                            {rate}x
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="play-btns">
                {Object.keys(channels).map((key, index) => {
                  if (key > 0) {
                    return playOne === index ? (
                      <div className="play" onClick={() => handlePlayOne(-1)}>
                        <PauseIcon />
                      </div>
                    ) : (
                      <div
                        className="play"
                        onClick={() => handlePlayOne(index)}
                      >
                        <PlayIcon />
                      </div>
                    );
                  } else return <></>;
                })}
              </div>

              <div className="audio-player">
                {channels.length === 1 ? (
                  <div
                    className={isAdmin || isAssigned ? '' : 'audio-disabled'}
                  >
                    <AudioPlayer
                      ref={AudioRef}
                      channel={channels}
                      source={channels[0]['url']}
                      playAll={playAll}
                      playbackRate={speed}
                      skipFwd={skipFwd}
                      skipBack={skipBack}
                      fetchData={refetchData}
                      setLoading={setLoading}
                      addTranscription={addTranscription}
                      isCompleted={completed}
                    />{' '}
                  </div>
                ) : (
                  Object.keys(channels).map(function (key, index) {
                    if (key > 0) {
                      return (
                        <div
                          className={
                            isAdmin || isAssigned
                              ? 'audio-player-container-div'
                              : 'audio-disabled'
                          }
                          key={index}
                        >
                          <AudioPlayer
                            key={index}
                            ref={(el) => (AudioRef.current[index] = el)}
                            keyId={index}
                            channel={channels[key]}
                            source={channels[key]['url']}
                            playAll={playAll}
                            playOne={playOne}
                            playbackRate={speed}
                            skipFwd={skipFwd}
                            skipBack={skipBack}
                            setLoading={setLoading}
                            addTranscription={addTranscription}
                            fetchData={refetchData}
                            isCompleted={completed}
                          />{' '}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                )}
              </div>
            </div>
            <div className="btn-keyboard-shortcuts-container">
              {showKeyboardShortcuts ? (
                <div className="shortcuts">
                  <button
                    className="btn-close-shortcuts"
                    onClick={() => setShowKeyboardShortcuts((prev) => !prev)}
                  >
                    <CloseIcon height="10px" width="10px" />
                  </button>
                  <div className="line">
                    <span className="key">{'↑'}</span>
                    <span className="action">Previous segment</span>
                    <span className="key">{'↓'}</span>
                    <span className="action">Next segment</span>
                    {/* </div> */}
                    {/* <div className="line"> */}
                    <span className="key">{'←'}</span>
                    <span className="action">Previous channel</span>
                    <span className="key">{'→'}</span>
                    <span className="action">Next channel</span>
                    {/* </div> */}
                    {/* <div className="line"> */}
                    <span className="key">Ctrl + Enter</span>
                    <span className="action">Play/Pause audio</span>
                    <span className="key">Enter</span>
                    <span className="action">Edit content</span>
                  </div>
                  <div className="line">
                    <span className="key">Esc</span>
                    <span className="action">Close edit modal</span>
                    <span className="key">Shift + d</span>
                    <span className="action">Delete segment</span>
                  </div>
                </div>
              ) : (
                <button
                  className="btn-keyboard-shortcuts"
                  onClick={() => setShowKeyboardShortcuts((prev) => !prev)}
                >
                  <ViewIcon height="20px" width="20px" /> Keyboard shortcuts
                </button>
              )}
            </div>
            {isAdmin || isAssigned ? (
              <div
                className={
                  completed
                    ? 'display-transcriptions-disabled'
                    : 'display-transcriptions'
                }
              >
                {channels.length !== 0 && (
                  <TranscriptionView
                    ref={TranscriptionViewRef}
                    channels={channels}
                    editTranscription={editTranscription}
                    deleteTranscription={deleteTranscription}
                    handleClick={handleClick}
                    isCompleted={completed}
                    activeSegment={activeSegment}
                    setChannelsList={setChannelsList}
                  />
                )}
              </div>
            ) : (
              <div className="assigned-view-wrapper">
                <div className="assigned-view">
                  You must be assigned to this audio to listen!
                </div>
                <div className="assigned-view-button">
                  {!isAssigned && (
                    <Button
                      label={'Assign to me'}
                      variant={'primary'}
                      onClick={handleAssignOpen}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <ConfirmModal
            heading={'Assign Transcription'}
            text={'Are you sure you want to Assign this transcription?'}
            handleClose={handleAssignClose}
            handleSubmit={handleAssign}
            visible={assignVisible}
          />
          <ConfirmModal
            heading={'Complete Transcription'}
            text={
              'Are you sure you want to mark this transcription as Completed?'
            }
            handleClose={handleMarkAsCompletedClose}
            handleSubmit={handleMarkAsCompleted}
            visible={markAsCompletedVisible}
          />
        </div>
      ) : (
        <div className="center-loader">
          <Loader />
        </div>
      )}
    </>
  );
}

export default AudioTranscription;
