import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './Loader.css';

function Loader({ label, type }) {
  return (
    <div className="loader">
      {type !== 'text' && <Spinner animation="border" />}
      {label && <div className="loader-label">{label}</div>}
    </div>
  );
}

export default Loader;
