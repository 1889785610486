import React from 'react';
import LoginComponent from './components/LoginComponent/LoginComponent';
import Main from './components/Main/Main';
import './Login.css';

export default function Login() {
  return (
    <div className="left-container">
      <div className="left-wrapper">
        <LoginComponent />
        <Main />
      </div>
    </div>
  );
}
