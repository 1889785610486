import { ReactComponent as Wave } from '../Assets/Icons/wave.svg';
import { ReactComponent as People } from '../Assets/Icons/people.svg';

export const SIDEBAR_OPTIONS = [
  { icon: <Wave />, link: '/Transcriptions', name: 'transcription' },
  {
    icon: <People />,
    link: '/Dashboard',
    name: 'freelancers',
  },
];
