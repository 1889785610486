import React from 'react';
import './Main.css';
import image from '../../../../Assets/Images/audiowave.png';

function Main() {
  return (
    <div className="right-container">
      {/* <img className='background' src={background}/> */}
      <div>
        <img className="image-container" src={image} alt={'Audio Wave'} />
      </div>
      <h1 className="tagline">
        Transcriptions Delivered,
        <br />
        Accurate & Reliable.
      </h1>
    </div>
  );
}

export default Main;
