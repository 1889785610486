export const convertDuration = (duration) => {
  var sec_num = parseInt(duration, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  if (hours === 0) {
    return `${minutes} mins ${seconds} seconds`;
  } else if (minutes === 0) {
    return `${seconds} seconds`;
  } else {
    return `${hours} hrs ${minutes} mins ${seconds} seconds`;
  }
};

export const calculateTimeFormat = (timeInSeconds) => {
  let totalSeconds = timeInSeconds;
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;
  let hoursBeforeFormatting = hours;

  // If you want strings with leading zeroes:
  minutes = String(minutes.toFixed(0)).padStart(2, '0');
  hours = String(hours.toFixed(0)).padStart(2, '0');
  seconds = String(seconds.toFixed(0)).padStart(2, '0');

  if (hoursBeforeFormatting === 0) {
    return `${minutes}:${seconds}`;
  } else {
    return `${hours}:${minutes}:${seconds}`;
  }
};
