import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from '../../Contexts/AuthContext';
export default function AuthRoute({ component: Component, ...otherProps }) {
  const { authenticated, loading, currentUser } = useContext(AuthContext);

  return (
    <Route
      {...otherProps}
      render={(props) =>
        loading ? (
          <div>loading</div>
        ) : currentUser && authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  );
}
