import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AuthRoute from '../Components/AuthRoute/AuthRoute';
import Login from '../pages/Login/Login';
import Dashboard from '../pages/Dashboard/Dashboard';
import Transcriptions from '../pages/Transcriptions/Transcriptions';
import AudioTranscription from '../pages/AudioTranscription/AudioTranscription';
import Profile from '../pages/Profile/Profile';

function Routes(props) {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" render={(props) => <Login {...props} />} exact />
        <AuthRoute path="/Dashboard" component={Dashboard} exact />
        <AuthRoute path="/Transcriptions" component={Transcriptions} exact />
        <AuthRoute
          {...props}
          path="/Transcriptions/AudioTranscription_:id"
          component={AudioTranscription}
          exact
        />
        <AuthRoute {...props} path="/Profile" component={Profile} exact />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
