import React from 'react';
import './Input.css';

const Input = ({
  value,
  label,
  name,
  placeholder,
  type,
  onChange,
  required,
  variant,
  disabled,
  ...props
}) => {
  return (
    <div className="form-group">
      {label && <label className="label-style">{label}</label>}
      <input
        className={`input-style ${variant} ${disabled ? 'input-disabled' : ''}`}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

export default Input;
