import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../Button/Button';
import './ConfirmModal.css';

const ConfirmModal = ({
  heading,
  text,
  handleClose,
  handleSubmit,
  visible,
}) => {
  return (
    <div>
      <Modal
        show={visible}
        onHide={handleClose}
        dialogClassName="confirmation-modal-container"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="confirmation-text">{text}</div>
          {/* <div className="confirm-actions">
            <div className="confirm-action-btn">
              <Button
                label={'Yes'}
                variant={'outline'}
                onClick={handleSubmit}
              />
            </div>
            <div className="confirm-action-btn">
              <Button label={'No'} variant={'outline'} onClick={handleClose} />
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button label={'Yes'} variant={'outline'} onClick={handleSubmit} />
          <Button label={'No'} variant={'outline'} onClick={handleClose} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
