import React, { useState, useEffect } from 'react';
import Topbar from '../../Components/Topbar/Topbar';
import Sidebar from '../../Components/Sidebar/Sidebar';
import defaultUser from '../../Assets/Icons/profile.svg';
import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';
import axios from 'axios';
import ls from 'local-storage';
import jwt_decode from 'jwt-decode';

import './Profile.css';

function Profile() {
  const [user, setUser] = useState();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const parentUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== oldPassword && newPassword === confirmPassword) {
      const reqHeaders = {
        authorization: ls.get('accessToken'),
      };
      const reqBody = {
        oldPassword: oldPassword,
        password: newPassword,
      };
      var response = await axios.post(
        `${parentUrl}users/updatePassword`,
        reqBody,
        { headers: reqHeaders }
      );
      console.log(response);
    }
  };

  useEffect(() => {
    var { name, email } = jwt_decode(ls.get('accessToken'));
    setUser({
      name: name,
      email: email,
    });
  }, []);

  return (
    <div className="dashboard-container">
      <Topbar title="Your Profile" subtitle={' '}>
        {' '}
      </Topbar>
      <Sidebar />
      <div className="profile-container">
        <div className="profile-picture-wrapper">
          <div>
            <img
              src={defaultUser}
              alt={"user's profile"}
              className="profile-picture"
            />
          </div>
          <div className="user-name">{user ? user.name : ''}</div>
          <div className="user-email">{user ? user.email : ''}</div>
        </div>
        <div className="input-container">
          <div className="input-wrapper">
            <div className="form-heading">Change your password</div>
            <form className="input-form" id="change-password-form">
              <Input
                label={'Old Password'}
                value={oldPassword}
                type={'password'}
                onChange={(e) => setOldPassword(e.target.value)}
                required={true}
                variant={'medium'}
              />
              <Input
                label={'New Password'}
                value={newPassword}
                type={'password'}
                onChange={(e) => setNewPassword(e.target.value)}
                required={true}
                variant={'medium'}
              />
              <Input
                label={'Confirm Password'}
                value={confirmPassword}
                type={'password'}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required={true}
                variant={'medium'}
              />
            </form>
            <Button
              label={'Change Password'}
              // variant={'outline'}
              form="change-password-form"
              type="submit"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
