import React from 'react';
import './Badge.css';

const Badge = ({ type, variant }) => {
  var clsName = '';
  var label = '';
  var style = '';

  switch (variant) {
    case 'text':
      style = 'no-fill';
      break;

    default:
      style = '';
      break;
  }

  switch (type) {
    case 'complete':
      clsName = 'completed';
      label = 'Completed';
      break;

    case 'assigned':
      clsName = 'in-progress';
      label = 'In Progress';
      break;

    case 'progress':
      clsName = 'in-progress';
      label = 'In Progress';
      break;

    case 'unassigned':
      clsName = 'not-started';
      label = 'Not Started';
      break;

    case 'verified':
      clsName = 'verified';
      label = 'Verified';
      break;
    case 'pending':
      clsName = 'pending';
      label = 'Pending';
      break;

    case 'transcribing':
      clsName = 'transcribing';
      label = 'Transcribing';
      break;

    default:
      clsName = 'default';
      label = '';
      break;
  }

  return <div className={`btn-style ${clsName} ${style}`}>{label}</div>;
};

export default Badge;
