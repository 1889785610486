/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useMemo,
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
} from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { WithContext as ReactTags } from 'react-tag-input';
// eslint-disable-next-line no-unused-vars
import { calculateTimeFormat } from '../../../../utils/timeFormatter';
import { ReactComponent as Edit } from '../../../../Assets/Icons/edit-area.svg';
import { ReactComponent as Delete } from '../../../../Assets/Icons/delete-area.svg';
import CloseIcon from '../../../../Assets/Icons/close.svg';

import Button from '../../../../Components/Button/Button';
import { AuthContext } from '../../../../Contexts/AuthContext';

import { KEYCODES } from '../../utils';

import './TranscriptionView.css';

const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

const TranscriptionView = forwardRef(
  (
    {
      channels,
      editTranscription,
      handleClick,
      deleteTranscription,
      isCompleted,
      activeSegment,
      setChannelsList,
    },
    ref
  ) => {
    const { isAdmin } = useContext(AuthContext);

    const channelsList = useMemo(() => {
      return { ...channels };
    }, [channels]);

    const activeSegmentRef = useRef(null);

    const [text, setText] = useState('');
    const [tags, setTags] = useState([]);
    const [open, setOpen] = useState(false);
    const [start, setStart] = useState(0);
    const [partId, setPartId] = useState('');
    const [end, setEnd] = useState(0);
    const [isEmpty, setIsEmpty] = useState(false);
    const [changed, setChanged] = useState(false);

    useEffect(() => {
      console.log('TAGS', tags);
    }, [tags]);

    const checkKeydownAction = (e) => {
      if (!activeSegment) return;

      if (
        e.keyCode !== KEYCODES.esc &&
        e.target.tagName.toLowerCase() !== 'body'
      )
        return;

      if (e.keyCode === KEYCODES.enter && e.ctrlKey) {
        // ctrl + enter is pressed
        e.preventDefault();
        activeSegmentRef.current
          .getElementsByClassName('notes-content-container')[0]
          .click();
      } else if (e.keyCode === KEYCODES.enter) {
        // enter key is pressed
        e.preventDefault();
        activeSegmentRef.current
          .getElementsByClassName('notes-actions')[0]
          .click();
        document.querySelector('.transcription-wrapper .text-area').focus();
      } else if (open && e.keyCode === KEYCODES.esc) {
        // edit modal is open and esc is pressed
        e.preventDefault();
        document
          .querySelectorAll('.transcription-text > .btn-close')[2]
          .click();
      } else if (e.shiftKey && e.keyCode === KEYCODES.d) {
        // shift + d is pressed
        e.preventDefault();
        activeSegmentRef.current
          .getElementsByClassName('notes-actions')[1]
          .click();
      }
    };

    useEffect(() => {
      if (activeSegment) {
        activeSegmentRef.current =
          document.getElementsByClassName('active-segment')[0];
        activeSegmentRef.current.scrollIntoViewIfNeeded();
      }

      window.addEventListener('keydown', checkKeydownAction);

      return () => {
        window.removeEventListener('keydown', checkKeydownAction);
      };
    }, [activeSegment]);

    const checkActiveSegmentRef = (channel, part) => {
      if (activeSegment?.channel === channel && activeSegment?.part === part)
        return activeSegmentRef;
      else return null;
    };

    const handleTranscriptionClick = (start, end, channelNo) => {
      setStart(start);
      setEnd(end);
      setPartId(partId);
      handleClick(start, end, channelNo);
    };

    const handleClose = () => {
      setOpen(false);
      setStart(0);
      setEnd(0);
      setPartId('');
      setText('');
      setTags([]);
    };

    const handleDelete = (id) => {
      console.log('delete');
      deleteTranscription(id);
    };

    const handleEdit = (partId, start, end, transcript, partTags) => {
      setText(transcript);
      setStart(start);
      setEnd(end);
      setPartId(partId);
      setOpen(true);
      setTags(partTags ?? []);
    };

    const handleSaveTranscription = () => {
      editTranscription(partId, text, start, end, tags);
      setOpen(false);
      console.log(`Save : ${start} ${end} ${text} ${partId}`);
    };

    useEffect(() => {
      var Empty = 0;
      Object.keys(channelsList).forEach(function (key, index) {
        if (channelsList[key].partsArray.length === 0) {
          Empty = Empty + 1;
        }
      });
      if (channelsList.length === Empty) {
        setIsEmpty(true);
      }
    }, [channelsList]);

    useEffect(() => {
      if (channelsList.length !== 0) {
        setOrder();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mergeSortedArray = (a, b) => {
      var indexA = 0,
        indexB = 0;
      var res1 = [];
      var res2 = [];

      while (indexA < a.length && indexB < b.length) {
        if (sortFn(a[indexA].start, b[indexB].start) > 0) {
          res1.push({ type: 'empty' });
          res2.push(b[indexB++]);
        } else {
          res2.push({ type: 'empty' });
          res1.push(a[indexA++]);
        }
      }

      if (indexB < b.length) {
        res2 = res2.concat(b.slice(indexB));
      } else {
        res1 = res1.concat(a.slice(indexA));
      }

      channelsList[1].partsArray.splice(
        0,
        channelsList[1].partsArray.length,
        ...res1
      );
      channelsList[2].partsArray.splice(
        0,
        channelsList[2].partsArray.length,
        ...res2
      );
      console.log('channels list modified', channelsList);
      setChanged(true);
      setChannelsList(channelsList);
    };

    const sortFn = (a, b) => {
      return a - b;
    };

    const setOrder = () => {
      setChanged(false);
      console.log(changed);
      console.log('called');
      let arr1 = [];
      let arr2 = [];

      try {
        if (
          channelsList[1].partsArray.length !== 0 &&
          channelsList[1].partsArray
        ) {
          channelsList[1].partsArray.forEach((part) => {
            arr1.push(part);
          });
        }

        if (
          channelsList[2].partsArray.length !== 0 &&
          channelsList[2].partsArray
        ) {
          channelsList[2].partsArray.forEach((part) => {
            arr2.push(part);
          });
        }

        mergeSortedArray(arr1, arr2);
      } catch (err) {
        console.log('err', err);
      }
    };

    const checkIsSegmentSelected = (channel, part) => {
      return activeSegment?.channel === channel && activeSegment?.part === part;
    };

    const handleTagDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };

    const handleTagAdd = (tag) => {
      setTags((prevTags) => [...prevTags, tag.text]);
    };

    const handleTagDrag = (tag, currPos, newPos) => {
      const newTags = [...tags];
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
      setTags(newTags.map((tag) => (tag.text ? tag.text : tag)));
    };

    const handleTagClick = (index) => {
      console.log('The tag at index ' + index + ' was clicked');
    };

    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    useImperativeHandle(ref, () => ({
      setOrder: setOrder,
    }));

    return (
      <>
        <div className="channel-container">
          <div className="channel-header">
            {Object.keys(channelsList).map(function (key, index) {
              // console.log('key', key, channelsList[key]['channelNumber']);
              let channelId = channelsList[key].channelNumber;
              if (channelsList.length !== 1 && key > 0) {
                return (
                  <div key={index}>
                    <div>Channel {channelId}</div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          <div className="channel-notes-wrapper">
            <div className="empty-notes">
              {isEmpty ? 'No Transcriptions yet!' : ''}
            </div>
            {Object.keys(channelsList).map(function (key, index) {
              if (channelsList.length !== 1 && key > 0) {
                return (
                  <div key={index}>
                    <div className="channel-notes" key={index}>
                      {channelsList[key].partsArray.map((part, ind) => {
                        return (
                          <div key={ind}>
                            {part.type === 'empty' ? (
                              <div className="empty" key={index} />
                            ) : (
                              <div
                                className={`notes-wrapper ${
                                  checkIsSegmentSelected(
                                    channelsList[key].channelNumber,
                                    ind
                                  )
                                    ? 'active-segment'
                                    : ''
                                }`}
                                ref={checkActiveSegmentRef}
                                key={index}
                              >
                                <div
                                  className="notes-content-container"
                                  onClick={() =>
                                    handleTranscriptionClick(
                                      part.start,
                                      part.end,
                                      key
                                    )
                                  }
                                >
                                  <div className="top">
                                    <div className={'notes-times'}>
                                      {`${calculateTimeFormat(
                                        part.start
                                      )} to ${calculateTimeFormat(part.end)}`}
                                    </div>
                                    {/* <div className="tags-container"> */}
                                    {part.tags?.map((tag) => (
                                      <span className="tag">{tag}</span>
                                    ))}
                                    {/* </div> */}
                                  </div>
                                  <div className="notes-content">{`${part.transcript}`}</div>
                                </div>
                                <div className="notes-action-container">
                                  <div
                                    className={`notes-actions-wrapper ${
                                      isCompleted && !isAdmin
                                        ? 'notes-actions-wrapper-disabled'
                                        : ''
                                    }`}
                                  >
                                    <div
                                      className="notes-actions"
                                      onClick={() =>
                                        handleEdit(
                                          part._id,
                                          part.start,
                                          part.end,
                                          part.transcript,
                                          part.tags
                                        )
                                      }
                                    >
                                      <Edit />{' '}
                                    </div>
                                    <div
                                      className="notes-actions"
                                      onClick={() => handleDelete(part._id)}
                                    >
                                      <Delete />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
        <Collapse in={open} dimension="width">
          <div
            className="transcription-wrapper"
            onKeyDown={checkKeydownAction}
            tabIndex={1}
          >
            <div className="transcription-text">
              <img
                className="btn-close"
                src={CloseIcon}
                alt={'close button'}
                onClick={handleClose}
              />
              <div>
                <ReactTags
                  tags={tags?.map((tag) => ({
                    id: tag,
                    text: tag,
                  }))}
                  handleDelete={handleTagDelete}
                  handleAddition={handleTagAdd}
                  handleDrag={handleTagDrag}
                  handleTagClick={handleTagClick}
                  onTagUpdate={onTagUpdate}
                  delimiters={delimiters}
                  placeholder="Add tag"
                  allowDeleteFromEmptyInput={true}
                  autocomplete={true}
                  readOnly={false}
                  allowUnique={true}
                  allowDragDrop={true}
                  inline={true}
                  editable={true}
                />
              </div>
              <div className="text-area-container">
                <textarea
                  className="text-area"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder={'Start typing...'}
                  autoFocus
                />
              </div>
              <div className="text-btn">
                <Button
                  label={'Save'}
                  variant={'primary'}
                  onClick={handleSaveTranscription}
                />
              </div>
            </div>
          </div>
        </Collapse>
      </>
    );
  }
);

export default TranscriptionView;
