import React from 'react';
import './Topbar.scss';

const Topbar = ({
  className = '',
  title = 'page-title',
  subtitle = 'subtitle',
  children,
  ...props
}) => {
  return (
    <div className={`topbar ${className}`} {...props}>
      <div className="left">
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
      <div className="right">{children}</div>
    </div>
  );
};

export default Topbar;
