import React from 'react';
import './Button.css';

const Button = ({ variant, label, onClick, type, form }) => {
  var clsName = '';

  switch (variant) {
    case 'outline':
      clsName = 'btn-outline';
      break;

    case 'fill-light':
      clsName = 'btn-fill lt';
      break;

    case 'primary':
      clsName = 'btn-fill-primary';
      break;

    case 'fill-green':
      clsName = 'btn-fill-green';
      break;

    default:
      clsName = 'btn-fill';
      break;
  }

  return (
    <button
      className={`custom-btn ${clsName}`}
      type={type}
      form={form}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Button;
