import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../../Button/Button';
import Input from '../../../Input/Input';
import axios from 'axios';
import ls from 'local-storage';
import './FreelancerModal.css';

const FreelancerModal = ({ visible, handleClose, reload }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');

  const parentUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email || !password) {
      alert('Please enter your email and password');
      return;
    } else if (password !== confirmPassword) {
      alert('Passwords do not match! please try again');
    } else if (!firstName || !lastName) {
      alert('Please enter your full name.');
    } else {
      try {
        const reqHeaders = {
          authorization: ls.get('accessToken'),
        };

        var response = await axios.post(
          `${parentUrl}users/createFreelancer`,
          {
            email: email,
            password: password,
            name: `${firstName} ${lastName}`,
          },
          {
            headers: reqHeaders,
          }
        );

        if (response.data.statusCode >= 200) {
          alert('Successfully created new freelancer');
          reload();
          handleClose();
        }
      } catch (err) {
        console.log('error', err);
        alert(
          err?.response?.data?.message ?? 'Failed to create new freelancer'
        );
      }
    }
  };

  return (
    <div>
      <Modal
        show={visible}
        onHide={handleClose}
        dialogClassName="freelancer-modal-container"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Freelancer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="freelancer-form-container">
            <form
              id="freelancer-form"
              className="freelancer-form-wrapper"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className="name-wrapper">
                <Input
                  label={'First Name'}
                  value={firstName}
                  onChange={(e) => setfirstName(e.target.value)}
                  required={true}
                  variant={'medium'}
                />
                <Input
                  label={'Last Name'}
                  value={lastName}
                  onChange={(e) => setlastName(e.target.value)}
                  required={true}
                  variant={'medium'}
                />
              </div>
              <Input
                label={'Email'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required={true}
                type={'email'}
                autocomplete="off"
              />
              <Input
                label={'Password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required={true}
                type={'password'}
                autocomplete="new-password"
              />
              <Input
                label={'Confirm Password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required={true}
                type={'password'}
                autocomplete="off"
              />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button label={'Close'} variant={'outline'} onClick={handleClose} />
          <Button
            label={'Submit'}
            // variant={'outline'}
            form="freelancer-form"
            type="submit"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FreelancerModal;
